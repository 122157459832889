import { Grid, Loader, Modal, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import Logs, { Log } from "../pages/Logs";
import { AddResult } from "../pages/MassScrubStep2";
import GeneralTable from "../Tables/GeneralTable";
import H2 from "../Texts/H2";

interface ILogModal {
  opened: boolean;
  close: () => void
}

const LoadingModal = ({ opened, close }: ILogModal) => {
  return (
    <Modal
      opened={opened}
      title={<H2 textAlign="center" text="Processing your file" fontSize="32px" fontWeight={300}  />}
      size="md"
      onClose={() => {}}
      centered
      withCloseButton={false}
    >
      <div className="flex flex-col items-center">
        We are processing your file. This could take some minutes to complete.

        <div className="py-5">
          <Loader
            color={'#0F182D'}
          />
        </div>

      </div>
    </Modal>);
}

export default LoadingModal;