export const ROUTES = {
  LOGOUT: "/logout",
  HOME: "/",
  PROFILE: "/profile",
  PHONE_SCRUB: "/phone-scrub",
  MASS_SCRUB_STEP_1: "/phone-scrub/mass-step-1",
  MASS_SCRUB_STEP_2: "/phone-scrub/mass-step-2",
  SINGLE_SCRUB: "/phone-scrub/single",
  LOGS: "/logs",
  USERS: "/users",
  SINGLE_USER: "/single-user/:id"
}