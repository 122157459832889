import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { useSetRecoilState } from "recoil";
import { apolloErrorModal } from "./components/state/atoms";

export const URI = process.env.REACT_APP_API_URI_GRAPHQL;

const ApolloWrapper = ({ children }: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const setErrorModal = useSetRecoilState(apolloErrorModal)

  const httpLink = createUploadLink({
    uri: URI,
  });

  const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors) {
      const messages: string[] = [];
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
        messages.push(message);
      }
      );
      setErrorModal({ show: true, errors: messages });
    }
    if (networkError) {
      const operationName = operation.operationName;
      if (operationName === 'uploadFile') {
        setErrorModal({ show: true, errors: ['File too big'] });
      } else
        console.log(`[Network error]: ${networkError}`);
    }
  });

  const authLink = setContext(async (_, { headers }) => {
    // return the headers to the context so httpLink can read them
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {},
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
