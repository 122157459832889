import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query allUsers($limit: Int!, $offset: Int!) {
    users(limit: $limit, offset: $offset) {
      email
      first_name
      last_name
      role
      id
    }
  }
`;

export const GET_USER_AUTH0 = gql`
  query user($id: ID!) {
    userAuth0(id: $id) {
      id
      first_name
      last_name
      email
      phone
      address
      state
      city
      zipcode
    }
  }
`;

export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      first_name
      last_name
      email
      role
    }
  }
`;

export const GET_USER_ROLE = gql`
  query user($id: ID!) {
    userRole(id: $id) 
  }
`;

export const GET_SEARCH_MASS_LOGS = gql`
  query Logs($limit: Int!, $offset: Int) {
    searchMassLogs(limit: $limit, offset: $offset) {
      id
      user_id
      user
      message {
        badNumbers
        cleanNumbers
        totalCount
        badCount
        cleanCount
        duplicateCount
        duration
        filename
        states
        scrub
        invalidCount
        invalidNumbers
        failed
        outputFileFormat
      }
      timestamp
      type
    }
  }
`;

export const GET_LOGS = gql`
  query Logs($limit: Int!, $offset: Int) {
    logs(limit: $limit, offset: $offset) {
      id
      user_id
      user
      message
      timestamp
      type
      sublogs {
        information
        timestamp
      }
    }
  }
`;

export const GET_SEARCH_MASS_LOGS_COUNT = gql`
  query Count {
    searchMassLogsCount
  }
`;

export const GET_LOGS_COUNT = gql`
  query Count {
    logsCount
  }
`;


export const GET_USERS_COUNT = gql`
  query Count {
    usersCount
  }
`;

export const GET_ALL_ROLES = gql`
  query roles {
    getAllRoles {
      name
      id
    }
  }
`;