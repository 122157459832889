import { useRecoilValue } from "recoil";
import { userRole } from "../components/state/atoms";
import { PERMISSION } from "../config/permissions";

export const usePermissions = () => {
    const userAuthority = useRecoilValue(userRole) as string;
    // Admin
    const hasAdminPermissions = () =>
        isAdmin();

    // Loan Officer
    const hasLOPermissions = () =>
        hasAdminPermissions() || isLoanOfficer();

    const isAdmin = () => {
        return userAuthority === PERMISSION.ADMIN;
    };

    const isLoanOfficer = () => {
        return userAuthority === PERMISSION.LOAN_OFFICER;
    };

    return {
        isAdmin,
        isLoanOfficer,
        hasAdminPermissions,
        hasLOPermissions
    };
};
