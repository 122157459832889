import { Text } from '@mantine/core';

interface BtnTextProps {
  text: string
  fontSize: string
}

export default function BtnText( { text, fontSize }: BtnTextProps ) {
  return (
    <>
    <Text weight={600} style={{ fontFamily: '', fontSize: fontSize  }}> 
        {text}
    </Text>
    </>
  );
}