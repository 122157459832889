import { Tooltip, Avatar, MantineProvider } from '@mantine/core';

interface InfoTooltipProps {
  text: string
}

export default function InfoTooltip({ text }: InfoTooltipProps) {
  return (

    <Tooltip
      wrapLines
      width={200}
      label={text}
      transition="fade"
      transitionDuration={200}
      radius="md"
      position="bottom"
      placement="start"
      styles={() => ({
        body: {
          background: 'white',
          "box-shadow": "1px 1px 5px #0f182d3d"
        }
      })}
    >
      <Avatar src={'/svg/info.svg'} size={16} />
    </Tooltip>
  );
}