import { Text, Button, Avatar, SimpleGrid, Input } from '@mantine/core';
import H2 from '../Texts/H2';
import { useState } from 'react';

interface RowsInfoProps {
    title: string
    register: any;
    value: string;
    error: any;
    editable?: boolean;
}

interface ProfileGridProps {
    rowsInfo2: RowsInfoProps[];
    editing?: boolean;
    editable?: boolean;
    setEditing?: any;
}

export default function ProfileEditingForm({ rowsInfo2, editing, editable, setEditing }: ProfileGridProps) {
    const [isEditing, setIsEditing] = useState(false);

    const onClick = (val: boolean) => {
        setIsEditing(val);
        setEditing(val);
    }

    return (isEditing || editing) ? (
        <div className='flex flex-row pt-10'>
            <div className='w-1/4'>
                <H2 text='Contact Information' fontSize='20px' fontWeight={600} />
            </div>
            <SimpleGrid cols={3}>
                {rowsInfo2.map((r, i) => {
                    return (
                        <div style={{ width: '220px' }} key={i}>
                            <div className='pb-2'>
                                <H2 text={r.title} fontSize='16px' fontWeight={600} />
                            </div>
                            <div className='pb-5'>
                                {r.editable === false ? (
                                    <Text style={{ color: '#737588', fontFamily: '', fontSize: '16px' }}> {r.value} </Text>
                                ) : (
                                    <>
                                        <Input placeholder={r.title} {...r.register} />
                                        <span className='text-[14px] text-rose-600'>{r.error && 'Invalid Input'}</span>
                                    </>
                                )}
                            </div>
                        </div>
                    )
                })}
            </SimpleGrid >
            {editable && <Button type='submit' onClick={() => onClick(false)} leftIcon={<Avatar src={'/svg/save.svg'} size={12} />} variant="white">
                <Text weight={600} style={{ color: '#0F182D', fontFamily: '', fontSize: '14px' }}> Save changes </Text>
            </Button>}
        </div >
    ) : (
        <div className='flex flex-row pt-10'>
            <div className='w-1/4'>
                <H2 text='Contact Information' fontSize='20px' fontWeight={600} />
            </div>
            <SimpleGrid cols={3}>
                {rowsInfo2.map((r, i) => {
                    return (
                        <div style={{ width: '220px' }} key={i}>
                            <div className='pb-2'>
                                <H2 text={r.title} fontSize='16px' fontWeight={600} />
                            </div>
                            <div className='pb-5'>
                                <Text style={{ color: '#737588', fontFamily: '', fontSize: '16px' }}> {r.value} </Text>
                            </div>
                        </div>
                    )
                })}
            </SimpleGrid>
            {editable && <Button type='submit' onClick={() => onClick(true)} leftIcon={<Avatar src={'/svg/edit.svg'} size={12} />} variant="white">
                <Text weight={600} style={{ color: '#809FB8', fontFamily: '', fontSize: '14px' }}> Edit Section </Text>
            </Button>}
        </div>
    );
}