import { Accordion, TextInput, Button, Group, Box, SimpleGrid, Loader } from '@mantine/core';
import { useForm } from 'react-hook-form';
import FormDD from '../Dropdowns/FormDD';
import DatePickerInput from '../Inputs/DatePicker';
import { useState } from "react";
import { useMutation } from '@apollo/client';
import { ADD_CONTACT } from '../../graphql/mutations';
import FilledBtn from '../Buttons/FilledBtn';

interface ScrubForm {
    name: string;
    phone: string;
    email: string;
}

export default function SingleScrubForm() {

    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const { handleSubmit, register, reset, formState: { errors } } = useForm<ScrubForm>();
    const [addContact, { error, loading }] = useMutation(ADD_CONTACT)
    const onSubmit = (data: ScrubForm) => {
        setSuccess(false);
        addContact({
            variables: { ...data }, onCompleted: (data) => {
                reset();
                setSuccess(true);
            }
        })
    }
    const getPhoneError = (type?: string) => {
        switch (type) {
            case 'required':
                return 'Phone field is required'
            case 'minLength':
            case 'maxLength':
                return 'Provide a 10-digit phone'
            case 'validate':
                return 'Invalid phone number'
        }
        return null;
    }
    return (
        <div className='pt-5'>
            <Button className='mb-5 gradient-button' onClick={() => setShow(!show)} type="submit" radius={'lg'}>
                <div className='flex items-center gap-3'>
                    Add phone number to internal DNC
                    <img src="/svg/plus.svg" alt="" />
                </div>
            </Button>

            {show && <div>
                <Box sx={{ maxWidth: 1045 }} >
                    <p className='pb-2'>
                        Exclude prefixes, parentheses, dashes, and spaces from phone number search
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <SimpleGrid cols={4} spacing="lg">
                            {/* <div><TextInput placeholder="Phone number" radius="md"/></div>
                            <div><FormDD/></div> */}
                            <div><TextInput {...register('name')} placeholder="Name" radius="md" /></div>
                            <div>
                                <TextInput {...register('phone', { required: true, minLength: 10, maxLength: 10, validate: (val) => /^[0-9]+$/.test(val) })} error={getPhoneError(errors?.phone?.type)} placeholder="Phone Number" radius="md" />
                            </div>
                            <div><TextInput {...register('email')} placeholder="Email" radius="md" /></div>
                            {/* <Button type="submit" radius={'lg'} style={{ width: '167px' }}>
                                {loading ? <Loader size={"sm"} sx={(theme) => ({ stroke: 'white' })} /> : 'Add'}
                            </Button> */}
                            <FilledBtn
                                width='167px'
                                height='36px'
                                bgColor='red'
                                hoverColor='#0F182D'
                                btnText='Add'
                                fontSize='16px'
                                loading={loading}
                                type={loading ? "button" : "submit"}
                            />
                        </SimpleGrid>
                        {success && <p className='text-sm pt-2 text-green'>Contact added succesfully</p>}
                    </form>
                </Box>
            </div>}
        </div>
    );
}