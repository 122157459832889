import { Select, Group, Text, SelectItem } from "@mantine/core";
import { ChevronDown } from "tabler-icons-react";
import { forwardRef } from "react";
import { SelectedIndexes } from "../pages/MassScrubStep2";

const data = [
  {
    label: "phone",
    value: "phone",
  },
  {
    label: "name",
    value: "name",
  },
  {
    label: "email",
    value: "email",
  },
];

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  disabled?: boolean;
}

const SelectItems = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, disabled, ...others }: ItemProps, ref) => (
    <div style={{ backgroundColor: disabled ? '#ededed' : '' }} ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text style={{ color: disabled ? "#c4c4c4" : "#656565", fontFamily: "", fontSize: "16px" }}>
            {label}
          </Text>
        </div>
      </Group>
    </div>
  )
);

interface CardDDProps {
  options: SelectItem[];
  onSelect: (value: keyof SelectedIndexes | null) => void;
}

export default function CardDD({ options, onSelect }: CardDDProps) {
  return (
    <Select
      clearable
      placeholder="Pick one"
      itemComponent={SelectItems}
      data={options}
      radius="md"
      rightSection={<ChevronDown size={14} />}
      rightSectionWidth={30}
      styles={{
        rightSection: { pointerEvents: "none" }, input: { '&:focus': { 'border-color': '#0F182D' } }, item: {
          // applies styles to selected item
          '&[data-selected]': {
            backgroundColor: 'red !important'
          },
        }
      }}
      onChange={onSelect}
    />
  );
}
