import { Table, Pagination, Button } from '@mantine/core';
import H1 from '../Texts/H1';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';

interface TitleOptionsProps {
    text: string
    fontSize: string
}

interface ElementsProps {
    name: string
    email: string
    role: string
    id: string;
}

interface GeneralTableProps {
    titleOptions: TitleOptionsProps []
    elements: ElementsProps []
}

export default function UsersTable( { titleOptions, elements }: GeneralTableProps ) {
  const navigate = useNavigate();

  const rows = elements.map((element) => (
      <tr onClick={() => navigate(ROUTES.SINGLE_USER.replace(':id', element.id))} className="hover:bg-[#ebebeb] cursor-pointer" style={{ height:'50px', alignItems:'center' }} key={element.name}>
        <td style={{ color: '#626262', fontSize:'14px', textTransform: 'capitalize' }}>
        {/* <Button variant="subtle" style={{ color: '#626262', textTransform: 'capitalize', fontWeight: 400 }} component={Link} to={}> */}
          {element.name}
        {/* </Button> */}
        </td>
        <td style={{ color: '#626262', fontSize:'14px' }}>{element.email}</td>
        <td style={{ color: '#626262', fontSize:'14px' }}>{element.role}</td>
      </tr> 
  ))

  return (
    <div>
      <Table style={{  }}>
        <thead className=''>
          <tr style={{ backgroundColor:'' }}>
              {titleOptions.map((t, i) => {
                  return (
                  <th key={t.text}>
                      <H1
                      text= {t.text}
                      fontSize= {t.fontSize}
                      />
                  </th>
              )})}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
}