import { Dialog, Transition } from '@headlessui/react'
import { useRecoilState } from 'recoil';
import { menuSidebarOpenState } from '../state/atoms';
import NavigationCard from './NavigationCard';

import { Fragment } from 'react';

interface MobileMenuProps{
    links: {
        link: string,
        color: string,
        text: string,
    }[]
}

export default function MobileMenu({ links }:MobileMenuProps) {

    const [sidebarOpen, setSidebarOpen] = useRecoilState(menuSidebarOpenState);

    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 flex z-50 xl:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <div className="relative flex-1 flex flex-col bg-white" style={{ maxWidth: 257 }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    type="button"
                                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full"
                                    onClick={() => setSidebarOpen(false)}
                                >
                                    <span className="sr-only">Close sidebar</span>
                                </button>
                            </div>
                        </Transition.Child>
                        <div className="flex-1 h-0 pb-4 overflow-y-auto">
                            <div>
                            <div className='flex justify-center pb-6 pt-3.5 w-60 bg-white'>
                                <img src="/Images/west-capital-lending-logo.png" alt="" />
                            </div>
                            <NavigationCard
                                links={links} 
                            />
                            </div>
                        </div>
                    </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
            </Dialog>
        </Transition.Root>
    )
}