import { Avatar, Button, Tooltip } from "@mantine/core";
import { useState } from "react";
import { GET_FILE_PATH } from "../../config/constants";
import { useDownloadDocument } from "../../hooks/useDownloadDocument";

interface TextBtnProps {
  file_name: string;
  btnText: string;
  s3key: string;
  iconColor: string;
  ext?: string;
}

export default function DownloadBtn({ btnText, s3key, iconColor, file_name, ext }: TextBtnProps) {
  const [loading, setLoading] = useState(false);
  const [setDownlaodUrl, setFileName, triggerDownload, isLoading] =
    useDownloadDocument(() => setLoading(false));

  const downloadDoc = () => {
    setLoading(true);
    const url = GET_FILE_PATH + s3key;
    setFileName(`${file_name}.${ext || "csv"}`);
    setDownlaodUrl(url);
    triggerDownload();
  };

  const textColors = () => {
    if (iconColor === 'red') return '#AA0000'
    if (iconColor === 'yellow') return '#F0CA42'
    if (iconColor === 'green') return '#287702'
  }

  return (
    <Tooltip
      label={ext || "csv"}
      styles={() => ({
        body: { color: 'white !important' },
      })}>
      <Button
        radius="xl"
        rightIcon={<Avatar src={`/svg/download_${iconColor}.svg`} size={12} className="mt-0.5 ml-0" />}
        styles={() => ({
          root: {
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "white",
            },
            color: textColors(),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center'
          },
          rightIcon: {
            marginLeft: '5px',
          }
        })}
        loading={loading}
        onClick={downloadDoc}
        loaderPosition="right"
        loaderProps={{
          color: textColors()
        }}
      >
        {btnText}
      </Button>
    </Tooltip>
  );
}
