import { Text, SimpleGrid, Select, Group, Button, Avatar } from '@mantine/core';
import H2 from '../Texts/H2';
import { ChevronDown } from 'tabler-icons-react';
import { forwardRef } from 'react'
import { useRecoilValue } from 'recoil';
import { roles } from '../state/atoms';

interface RowsInfoProps {
    title: string
    text: string
}

interface SingleUserTableProps {
    rowsInfo1: RowsInfoProps[]
    setValue: (key: "role", val: string) => void
}


export default function SingleUserTable({ rowsInfo1, setValue }: SingleUserTableProps) {
    const allRoles = useRecoilValue(roles);
    interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
        label: string;
    }

    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
        ({ label, ...others }: ItemProps, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <div>
                        <Text style={{ color: '#656565', fontFamily: '', fontSize: '16px' }}>{label}</Text>
                    </div>
                </Group>
            </div>
        )
    );

    return (
        <div className='flex flex-row gap-4'>
            <div className='w-2/4 lg:w-1/4'>
                <H2 text='Basic Information' fontSize='20px' fontWeight={600} />
            </div>
            <div className='hidden lg:block lg:w-2/4'>
                <SimpleGrid cols={2}>
                    {rowsInfo1.map((r, i) => {
                        if (r.title === 'Role') {
                            return (
                                <Select
                                    key={r.title}
                                    label={r.title}
                                    itemComponent={SelectItem}
                                    value={r.text}
                                    data={allRoles.map(r => ({ label: r.name, value: r.id }))}
                                    radius='md'
                                    rightSection={<ChevronDown size={14} />}
                                    rightSectionWidth={30}
                                    styles={{ rightSection: { pointerEvents: 'none' } }}
                                    onChange={(val) => setValue("role", val || "")}                                    
                                />
                            )
                        }
                        return (
                            <div className='pr-3' style={{ width: '100%' }} key={i}>
                                <div className='pb-3'>
                                    <H2 text={r.title} fontSize='16px' fontWeight={600} />
                                </div>
                                <div className='pb-5'>
                                    <Text style={{ color: '#737588', fontFamily: '', fontSize: '16px' }}> {r.text} </Text>
                                </div>
                            </div>
                        )
                    })}

                </SimpleGrid>
            </div>
            <div className='block lg:hidden'>
                <SimpleGrid cols={1}>
                    {rowsInfo1.map((r, i) => {
                        return (
                            <div style={{ width: '100%' }} key={i}>
                                <div className='pb-2'>
                                    <H2 text={r.title} fontSize='16px' fontWeight={600} />
                                </div>
                                <div className='pb-5'>
                                    <Text style={{ color: '#737588', fontFamily: '', fontSize: '16px' }}> {r.text} </Text>
                                </div>
                            </div>
                        )
                    })}
                </SimpleGrid>
            </div>
            <div className='w-1/4'>
            <Button type='submit' onClick={() => {}} leftIcon={<Avatar src={'/svg/save.svg'} size={12} />} variant="white">
                <Text weight={600} style={{ color: '#0F182D', fontFamily: '', fontSize: '14px'  }}> Save changes </Text>
            </Button>
            </div>
        </div>
    );
}