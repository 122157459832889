import { LoadingOverlay } from "@mantine/core"

interface ILoader {
    loading: boolean;
}

const LoaderOverlay = ({ loading }: ILoader) => {
    return (
        <LoadingOverlay
            visible={loading}
            loaderProps={{ color: '#0F182D' }}
        />
    )
}

export default LoaderOverlay;