import { useAuth0 } from "@auth0/auth0-react";
import fileDownload from "js-file-download";
import { useState, useEffect } from "react";
import { GET_VERIFICATION_PATH } from "../config/constants";

export const useSendVerification = (
    onSuccessTrigger: () => void,
): [() => void, (val: string) => void, boolean] => {

    const [isLoading, setLoading] = useState(false); // Used for loading indicator while saving
    const [id, setId] = useState("");
    const triggerEmail = () => {
        if (!isLoading) setLoading(true);
    };

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            const [result, error] = await fetcher(GET_VERIFICATION_PATH, id);
            if (result && mounted) {
                if (onSuccessTrigger) onSuccessTrigger();
            }
            setLoading(false); // stop loading indicator for either case.
        };

        if (isLoading && id) fetchData();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return [triggerEmail, setId, isLoading];
};

export const fetcher = async (url: string, id: string) => {
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id })
        });

        if (!response.ok) {
            throw response;
        }
        return [response, null];
    } catch (error: any) {
        return [null, error];
    }
};