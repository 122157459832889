import { Checkbox } from "@mantine/core";

interface GeneralCBProps {
  setChecked?: (checked: boolean) => void;
  disabled?: boolean;
  id?: string;
}

export default function GeneralCB({ setChecked, disabled, id }: GeneralCBProps) {
  return (
    <Checkbox
      color={"red"}
      id={id}
      onChange={(event) =>
        setChecked && setChecked(event.currentTarget.checked)
      }
      disabled={disabled}
    />
  );
}
