import { Card, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import CardDD from "../Dropdowns/CardDD";
import { SelectedIndexes } from "../pages/MassScrubStep2";

interface TextProps {
  text: string;
}

interface ColorCardsProps {
  descriptions: TextProps[];
  cardIndex: number;
  selected: SelectedIndexes;
  onSelect: (value: keyof SelectedIndexes | null, cardIndex: number) => void;
  onExport: () => void;
  isExported: any;
  onSelectColumn: () => void;
  isKeepHeader: boolean;
  onKeepHeader: () => void;
}

export default function ColorCards({
  descriptions,
  cardIndex,
  selected,
  onSelect,
  onExport,
  isExported,
  onSelectColumn,
  isKeepHeader,
  onKeepHeader,
}: ColorCardsProps) {
  const [options, setOptions] = useState([
    {
      label: "Name",
      value: "first_name",
      disabled: false,
    },
    {
      label: "Last Name",
      value: "last_name",
      disabled: false,
    },
    {
      label: "Email",
      value: "email",
      disabled: false,
    },
    {
      label: "Phone",
      value: "phone",
      disabled: false,
    },
    {
      label: "Phone 2",
      value: "phone2",
      disabled: false,
    },
    {
      label: "Phone 3",
      value: "phone3",
      disabled: false,
    },
    {
      label: "Phone 4",
      value: "phone4",
      disabled: false,
    },
  ]);

  const selectHelper = (value: keyof SelectedIndexes | null) => {
    onSelect(value, cardIndex);
    if (value) onSelectColumn();
  }

  useEffect(() => {
    setOptions([
      {
        label: "Name",
        value: "first_name",
        disabled: selected.first_name !== undefined && selected.first_name !== cardIndex,
      },
      {
        label: "Last Name",
        value: "last_name",
        disabled: selected.last_name !== undefined && selected.last_name !== cardIndex,
      },
      {
        label: "Email",
        value: "email",
        disabled: selected.email !== undefined && selected.email !== cardIndex,
      },
      {
        label: "Phone",
        value: "phone",
        disabled: selected.phone !== undefined && selected.phone !== cardIndex,
      },
      {
        label: "Phone 2",
        value: "phone2",
        disabled: selected.phone2 !== undefined && selected.phone2 !== cardIndex,
      },
      {
        label: "Phone 3",
        value: "phone3",
        disabled: selected.phone3 !== undefined && selected.phone3 !== cardIndex,
      },
      {
        label: "Phone 4",
        value: "phone4",
        disabled: selected.phone4 !== undefined && selected.phone4 !== cardIndex,
      },
    ]);
  }, [selected]);

  const selectedColumn = selected.phone === cardIndex ||
    selected.email === cardIndex ||
    selected.last_name === cardIndex ||
    selected.phone2 === cardIndex ||
    selected.phone3 === cardIndex ||
    selected.phone4 === cardIndex ||
    selected.first_name === cardIndex

  return (
    <div style={{ width: 261, height: '100%' }}>
      <Card radius={"lg"} p="lg" style={{ backgroundColor: "#ebebeb" }}>
        <div className="flex justify-center">
          <CardDD options={options} onSelect={selectHelper} />
        </div>
        <div className="px-3 pt-3 pb-2">
          {descriptions.map((d, i) => {
            return (
              <Text
                key={i}
                className={`${i === 0 ? 'pt-2' : 'py-1.5'} truncate`}
                style={{
                  color: "#0F182D",
                  fontFamily: "",
                  fontSize: "14px",
                  fontWeight: i === 0 ? 'bold' : 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  lineClamp: 1
                }}
              >
                {d.text}
              </Text>
            );
          })}
        </div>
        <div className="flex flex-row items-center text-sm gap-x-2 text-[#0f182d] font-nunito">
          <input
            disabled={selectedColumn}
            id={`export-${cardIndex}`}
            type={'checkbox'}
            onChange={onExport}
            checked={isExported || selectedColumn}
          />
          <label className="pt-1" htmlFor={`export-${cardIndex}`}>Export</label>
        </div>
        <div className="flex flex-row items-center text-sm gap-x-2 text-[#0f182d] font-nunito">
          <input
            id={`keepHeader-${cardIndex}`}
            type={'checkbox'}
            disabled={isExported || !selectedColumn}
            onChange={onKeepHeader}
            checked={isKeepHeader || isExported}
          />
          <label className="pt-1" htmlFor={`keepHeader-${cardIndex}`}>Keep Header</label>
        </div>
      </Card>
    </div>
  );
}
