export const USERS_PER_PAGE = 10;
export const SEARCH_MASS_LOGS_PER_PAGE = 10;
export const LOGS_PER_PAGE = 20;
export const GET_FILE_PATH = process.env.REACT_APP_GET_FILE_API_PATH;
export const GET_VERIFICATION_PATH = process.env.REACT_APP_GET_VERIFICATION_API_PATH || "";
export const LOG_TYPE_PRETIFY = {
  "search_mass": "Bulk Search",
  "search_single": "  Single Search",
  "add_mass": "  Add Bulk Phones",
  "add_single": "  Add Single Phone",
  "daily_dnc_update": "Daily DNC Update Done",
}
