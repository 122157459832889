import { Select, Group, Avatar, Text, Button } from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';
import { Image } from '@mantine/core';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ROUTES } from '../../routes/config';

const data = [
  {
    label: 'Profile',
    value: 'Profile',
    image: '/svg/profile.svg',
    textColor: '#0F182D',
    link: ROUTES.PROFILE
  },

  {
    label: 'Logout',
    value: 'Logout',
    image: '/svg/logout.svg',
    textColor: '#FD0303',
    link: ROUTES.LOGOUT
  },
];

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  image: string;
  textColor: string;
  link: string
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, link, textColor, ...others }: ItemProps, ref) => (
    <Button
      variant="subtle"
      color="#ebebeb"
      style={{ height: '56px' }}
      styles={(theme) => ({
        root: {
          '&:hover': { backgroundColor: '#c0c3cc' }
        }
      })}
      component={Link} to={link}
    >
      <div className='flex justify-start items-center gap-4'>
        <Image src={image} height={'auto'} width={18} className="!rounded-none object-contain" />
        <div>
          <Text style={{ color: textColor, fontFamily: '', fontSize: '16px' }}>{label}</Text>
        </div>
      </div>
    </Button>
  )
);

export default function ProfileDD() {
  const { user } = useAuth0();
  return (
    <Select
      placeholder={user?.name || 'Welcome'}
      rightSection={<ChevronDown size={14} />}
      rightSectionWidth={30}
      styles={{ rightSection: { pointerEvents: 'none' } }}
      itemComponent={SelectItem}
      data={data}
      icon={<Image radius={"xl"} src={user?.picture} />}
      radius='xl'
      style={{ width: `${(user?.name || 'Welcome').replace(/ /g, '').length + 10}ch` }}
    />
  );
}