import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/config";
import NavigationCard from "../Navigation/NavigationCard";

interface SideBarProps{
    links: {
        link: string,
        color: string,
        text: string,
    }[]
}

export default function SideBar( { links }:SideBarProps ) {
  return (
      <div>
          <div className='hidden md:block'>
                <div className='fixed w-60 z-10'>
                    <Link to={ROUTES.PHONE_SCRUB} >
                        <div className='flex justify-center px-5 pb-6 pt-3.5 w-60 bg-white'>
                                <img src="/Images/west-capital-lending-logo.png" alt="" />
                        </div>
                    </Link>
                    <NavigationCard
                        links={links}  
                    />
                </div>
            </div>
      </div>
  );
}