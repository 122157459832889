import { Avatar, Loader, Table, Text, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import DownloadBtn from "../Buttons/DownloadBtn";
import H1 from "../Texts/H1";
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

interface TitleOptionsProps {
  text: string;
  fontSize: string;
  textAlign: "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start";
}

interface ScrubProps {
  text: string;
}

interface MatchesProps {
  internal: string;
  ftc: string;
  tpca_litigator: string;
}

interface InfoProps {
  text: string;
}

interface ElementsProps {
  time: string;
  file: string;
  user: string;
  numbers: string;
  scrub: ScrubProps[];
  matches: MatchesProps;
  info: InfoProps[];
  downloadsM?: string;
  downloadsC?: string;
  downloadsI?: string;
  badNumbers: number;
  cleanNumbers: number;
  badCount: any;
  invalidNumbers: number;
  duplicateNumbers: number;
  status: string;
  states: string;
  failed?: boolean;
  outputFileFormat?: string;
}

interface ScrubLogsTableProps {
  titleOptions: TitleOptionsProps[];
  elements: ElementsProps[];
  loading: boolean
}


export default function ScrubLogsTable({
  titleOptions,
  elements,
  loading
}: ScrubLogsTableProps) {
  const rows =
    elements.length > 0
      ? elements.map((element) => (
        <tr className="text-center" style={{ verticalAlign: "text-top" }} key={element.time}>
          <td className="text-left whitespace-nowrap" style={{ color: "#626262" }}>
            {dayjs(element.time).format('MM/DD/YY hh:mm a')}
          </td>
          <td className="" style={{ color: "#626262" }}>
            <div className="flex items-center">
              <Tooltip
                label={element.file}
                styles={() => ({
                  body: { color: 'white !important' },
                })}>
                <Text lineClamp={1} className="text-[14px] text-left cursor-default">{element.file}</Text>
              </Tooltip>
            </div>
          </td>
          <td style={{}}>
            {element.numbers}
          </td>
          <td>
            {element.duplicateNumbers?.toString() || 0}
          </td>
          <td>
            {element.matches.tpca_litigator || 0}
          </td>
          <td>
            {element.matches.internal || 0}
          </td>
          <td>
            {element.matches.ftc || 0}
          </td>

          <td className="">
            {element.downloadsM ? (
              <DownloadBtn
                file_name="Bad Numbers"
                btnText={element.badNumbers?.toString()}
                s3key={element.downloadsM}
                iconColor="red"
                ext={element.outputFileFormat}
              />
            ) : (
              "0"
            )}
          </td>
          <td>
            {element.downloadsC ? (
              <DownloadBtn
                file_name="Clean Numbers"
                btnText={element.cleanNumbers?.toString()}
                s3key={element.downloadsC}
                iconColor="green"
                ext={element.outputFileFormat}
              />
            ) : (
              "0"
            )}
          </td>
          <td>
            {element.downloadsI ? (
              <DownloadBtn
                file_name="Invalid Numbers"
                btnText={element.invalidNumbers?.toString()}
                s3key={element.downloadsI}
                iconColor="yellow"
                ext={element.outputFileFormat}
              />
            ) : (
              "0"
            )}
          </td>
          {/* <td style={{ color: "#626262", fontSize: "12px", maxWidth: '200px' }}>
            <SimpleGrid cols={1} spacing="xs">
              {element.scrub.map((s, i) => {
                return <div key={i}>{s.text}</div>;
              })}
              {element.states}
            </SimpleGrid>
          </td> */}
          {/* <td style={{ color: "#626262", fontSize: "12px" }}>
            <SimpleGrid cols={1} spacing="xs">
              {element.matches.map((m, i) => {
                return <div key={i}>{m.text}</div>;
              })}
            </SimpleGrid>
          </td> */}
          {/* <td style={{ color: "#626262", fontSize: "12px" }}>
            <SimpleGrid cols={1} spacing="xs">
              {element.info.map((inf, i) => {
                return <div key={i}>{inf.text}</div>;
              })}
            </SimpleGrid>
          </td> */}

          <td style={{ color: "#626262" }}>
            <div className="flex items-center justify-center">
              {element.status === 'Ready' && !element.failed && (
                <Tooltip
                  label={'Ready'}
                  styles={() => ({
                    body: { color: 'white !important' },
                  })}>
                  <Avatar src={`/svg/ready.svg`} size={12} />
                </Tooltip>
              )}
              {element.status === 'In Progress' && !element.failed && (
                <Tooltip
                  label={'Progress'}
                  styles={() => ({
                    body: { color: 'white !important' },
                  })}>
                  <Avatar src={`/svg/in-progress.svg`} size={12} />
                </Tooltip>
              )}
              {element.failed && (
                <Tooltip
                  label={'Something went wrong. Try again'}
                  styles={() => ({
                    body: { color: 'white !important' },
                  })}>
                  <Avatar src={`/svg/error.svg`} size={12} />
                </Tooltip>
              )}
            </div>
          </td>
          <td style={{ color: "#626262", textAlign: 'left', whiteSpace: 'nowrap' }}>
            {element.user}
          </td>
        </tr>
      ))
      : null;

  return (
    <Table className="w-full">
      <thead className="relative">
        <tr style={{ backgroundColor: "" }}>
          {titleOptions.map((t, i) => {
            return (
              <th className="min-[80px]" key={i}>
                <H1 text={t.text} fontSize={t.fontSize} textAlign={t.textAlign} />
              </th>
            );
          })}
        </tr>
      </thead>
      {loading ? (
        // Loading state 
        <tbody>
          <tr>
            <td colSpan={100} className="text-center">
              <div className="w-full flex justify-center pr-36 py-10">
                <Loader
                  color={'#0F182D'}
                />
              </div>
            </td>
          </tr>
        </tbody>
      ) : (
        rows ? (
          // If any rows created, show them
          <tbody>{rows}</tbody>
        ) : (
          // If no rows, show message
          <tbody>
            <tr>
              <td colSpan={100} className="text-center">
                <div className="w-full flex justify-center pr-36 py-10">
                  No results found
                </div>
              </td>
            </tr>
          </tbody>)
      )}
    </Table>
  );
}
