import { Table, SimpleGrid, Loader } from "@mantine/core";
import H1 from "../Texts/H1";
import DownloadBtn from "../Buttons/DownloadBtn";

interface TitleOptionsProps {
  text: string;
  fontSize: string;
  accessor: string;
}

interface GeneralTableProps {
  titleOptions: TitleOptionsProps[];
  elements: any[];
  loading: boolean
}

export default function GeneralTable({
  titleOptions,
  elements,
  loading
}: GeneralTableProps) {
  const rows =
    elements.length > 0
      ? elements.map((element, i) => {
        const cells = titleOptions.map(h => {
            return (
              <td key={element[h.accessor]}>{element[h.accessor]}</td>
            )
          })
        return (
          <tr className="hover:bg-[#ebebeb] cursor-pointer"  onClick={() => element.onclick()} key={i + '-log-row'} >{cells}</tr>
        )
        })
      : null;

  return (
    <Table className="w-full">
      <thead className="relative">
        <tr style={{ backgroundColor: "" }}>
          {titleOptions.map((t, i) => {
            return (
              <th key={i}>
                <H1 text={t.text} fontSize={t.fontSize} />
              </th>
            );
          })}
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={100} className="text-center">
              <div className="w-full flex justify-center pr-36 py-10">
                <Loader 
                  color={'#0F182D'}
                />
              </div>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>{rows}</tbody>
      )}
    </Table>
  );
}
