import { Card } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import { usePermissions } from '../../hooks/usePermissions';

interface NavigationCardProps {
    links: {
        link: string,
        color: string,
        text: string,
    }[]
}

export default function NavigationCard({ links }: NavigationCardProps) {
    const { hasAdminPermissions } = usePermissions();
    const DashboardIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="17.157" viewBox="0 0 18.5 17.157" fill="#809FB8">
            <path id="dashboard" d="M11.1,17.157a.894.894,0,0,1-.925-.858V10.294a.893.893,0,0,1,.925-.858h6.475a.893.893,0,0,1,.925.858V16.3a.893.893,0,0,1-.925.858Zm.925-1.716H16.65V11.152H12.025ZM.925,17.157A.893.893,0,0,1,0,16.3V10.294a.893.893,0,0,1,.925-.858H7.4a.893.893,0,0,1,.925.858V16.3a.894.894,0,0,1-.925.858ZM1.85,15.44H6.475V11.152H1.85ZM11.1,7.72a.893.893,0,0,1-.925-.857V.857A.893.893,0,0,1,11.1,0h6.475A.893.893,0,0,1,18.5.857V6.863a.893.893,0,0,1-.925.857ZM12.025,6H16.65V1.716H12.025ZM.925,7.72A.893.893,0,0,1,0,6.863V.857A.893.893,0,0,1,.925,0H7.4a.893.893,0,0,1,.925.857V6.863A.893.893,0,0,1,7.4,7.72ZM1.85,6H6.475V1.716H1.85Z" fill={links[0].color} />
        </svg>
    )

    const PhoneScrubIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.884" height="16.915" viewBox="0 0 16.884 16.915">
            <path id="Icon_feather-phone" data-name="Icon feather-phone" d="M16.238,18.414c-.069,0-.139,0-.208-.009h-.014a16.116,16.116,0,0,1-6.99-2.486,15.917,15.917,0,0,1-4.861-4.861A16.113,16.113,0,0,1,1.678,4.034V4.022A2.314,2.314,0,0,1,3.98,1.5H6.31A2.314,2.314,0,0,1,8.6,3.49V3.5a9.146,9.146,0,0,0,.5,2,2.32,2.32,0,0,1-.521,2.44l0,0-.553.553a11.579,11.579,0,0,0,3.537,3.537l.553-.553,0,0a2.314,2.314,0,0,1,2.441-.521,9.142,9.142,0,0,0,2,.5h.007a2.314,2.314,0,0,1,1.99,2.338v2.3a2.314,2.314,0,0,1-2.314,2.322Zm-.064-1.545.064,0a.771.771,0,0,0,.771-.774s0,0,0,0V13.78q0-.01,0-.019a.771.771,0,0,0-.661-.783,10.691,10.691,0,0,1-2.332-.581.771.771,0,0,0-.814.171l-.978.978a.771.771,0,0,1-.927.125A13.119,13.119,0,0,1,6.38,8.754a.771.771,0,0,1,.125-.927l.978-.978a.774.774,0,0,0,.172-.813A10.7,10.7,0,0,1,7.073,3.7a.775.775,0,0,0-.763-.661H3.981a.771.771,0,0,0-.767.836,14.563,14.563,0,0,0,2.248,6.343l0,.006a14.363,14.363,0,0,0,4.391,4.391l.006,0A14.566,14.566,0,0,0,16.174,16.869Z" transform="translate(-1.668 -1.5)" fill={links[1].color} />
        </svg>
    )

    const UsersIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.296" height="16" viewBox="0 0 22.296 16">
            <g id="Grupo_1699" data-name="Grupo 1699" transform="translate(-4.197 -386.687)">
                <path id="profile" d="M8.318,12.532V11.212a2.031,2.031,0,0,0-2.08-1.978h-3.6c-1.148,0-3.864-1.319-1.949-1.319H6.238a3.385,3.385,0,0,1,3.466,3.3v1.319a.694.694,0,0,1-1.386,0ZM0,3.5A3.5,3.5,0,1,1,3.5,7,3.5,3.5,0,0,1,0,3.5Zm1.4,0A2.1,2.1,0,1,0,3.5,1.4,2.1,2.1,0,0,0,1.4,3.5Z" transform="translate(16.79 389.496)" fill={links[2].color} />
                <path id="profile-2" data-name="profile" d="M13.451,15.2V13.6a2.463,2.463,0,0,0-2.522-2.4H4.2a2.464,2.464,0,0,0-2.523,2.4v1.6a.821.821,0,0,1-.841.8A.821.821,0,0,1,0,15.2V13.6a4.105,4.105,0,0,1,4.2-4H10.93a4.1,4.1,0,0,1,4.2,4v1.6a.842.842,0,0,1-1.681,0ZM3.362,4a4,4,0,1,1,4,4A4,4,0,0,1,3.362,4Zm1.6,0a2.4,2.4,0,1,0,2.4-2.4A2.4,2.4,0,0,0,4.963,4Z" transform="translate(4.197 386.687)" fill={links[2].color} />
            </g>
        </svg>
    )

    const LogsIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="25.1967622665" viewBox="0 0 20.014 28.016">
            <path id="Form_13" data-name="Form 13" d="M732,319l-.07,0H726a34.708,34.708,0,0,1-3.572-.1,3.237,3.237,0,0,1-3.325-3.323A34.437,34.437,0,0,1,719,312V298a34.719,34.719,0,0,1,.1-3.572,3.237,3.237,0,0,1,3.323-3.325A34.437,34.437,0,0,1,726,291h5.84a.977.977,0,0,1,.144-.013h0a.961.961,0,0,1,.209.02,4.774,4.774,0,0,1,2.26.357,19.116,19.116,0,0,1,4.185,4.189,4.761,4.761,0,0,1,.355,2.254,1,1,0,0,1,.006.362V312a34.4,34.4,0,0,1-.1,3.574,3.237,3.237,0,0,1-3.325,3.323,32.964,32.964,0,0,1-3.425.1Zm-9.352-25.913a1.344,1.344,0,0,0-1.559,1.561c-.084.746-.089,1.855-.089,3.35v14c0,1.5.005,2.605.089,3.352a1.343,1.343,0,0,0,1.561,1.558c.746.084,1.855.09,3.35.09h6l.07,0c1.454,0,2.547-.007,3.28-.089a1.346,1.346,0,0,0,1.561-1.559c.084-.746.089-1.855.089-3.352V299h-5a1,1,0,0,1-1-1v-5h-5C724.5,293,723.394,293.006,722.648,293.09ZM733,297h3.976a1.429,1.429,0,0,0-.127-.553,21.074,21.074,0,0,0-3.293-3.3,1.453,1.453,0,0,0-.556-.127h0Zm1,17H723.972a1,1,0,0,1,.028-2h10.029a1,1,0,0,1-.014,2Zm-10.028-4a1,1,0,0,1,.028-2h10.029a1,1,0,0,1-.029,2H723.972ZM734,306H723.972a1,1,0,0,1,.028-2h10.029a1,1,0,0,1-.014,2Z" transform="translate(-718.999 -290.986)" fill={links[2].color} />
        </svg>
    )

    return (
        <div style={{ width: 240, height: '100%' }}>
            <Card shadow="sm" style={{ backgroundColor: '#ebebeb', minHeight: '100vh', height: '100%', padding: '0px', borderRadius: "0px" }}>
                <nav>
                    {/* <div className='pb-2'>
                        <NavLink to={links[0].link} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                            <div className='flex items-center'>
                                <div className='pr-4'><DashboardIcon/></div>
                                {links[0].text}
                            </div>
                        </NavLink>
                    </div> */}
                    <div className='pb-2'>
                        <NavLink to={links[0].link} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                            <div className='flex items-center'>
                                <div className='pr-4'><PhoneScrubIcon /></div>
                                {links[0].text}
                            </div>
                        </NavLink>
                    </div>
                    {hasAdminPermissions() && <div className='pb-2'>
                        <NavLink to={links[1].link} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                            <div className='flex items-center'>
                                <div className='pr-4'><UsersIcon /></div>
                                {links[1].text}
                            </div>
                        </NavLink>
                    </div>}
                    {hasAdminPermissions() && <div className='pb-2'>
                        <NavLink to={links[2].link} className={({ isActive }) => (isActive ? "link-active" : "link")}>
                            <div className='flex items-center'>
                                <div className='pr-4'><LogsIcon /></div>
                                {links[2].text}
                            </div>
                        </NavLink>
                    </div>}
                </nav>
            </Card>
        </div>
    );
}