import { useMutation } from "@apollo/client";
import { Button, Checkbox, Group, MultiSelect, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { UPLOAD_FILE } from "../../graphql/mutations";
import LeftTextBtn from "../Buttons/LeftTextBtn";
import BoxCB from "../Checkboxes/BoxCB";
import GeneralCB from "../Checkboxes/GeneralCB";
import ProfileDD from "../Dropdowns/ProfileDD";
import DropZone from "../DropZones/DropZone";
import SimpleInput from "../Inputs/SimpleInput";
import NavBar from "../Layout/NavBar";
import SideBar from "../Layout/SideBar";
import H1 from "../Texts/H1";
import H2 from "../Texts/H2";
import Paragraph from "../Texts/Paragraph";
import InfoTooltip from "../Tooltips/InfoTooltip";
import states from '../../config/states.json';
import { useForm } from "react-hook-form";
import { ROUTES } from "../../routes/config";
import { usePermissions } from "../../hooks/usePermissions";

interface ScrubForm {
  states: string[];
  federalDnc: boolean;
  tcpa: boolean;
  dncComplainers: boolean;
  compareName: boolean;
  addNumbers: boolean;
}

const DEFAULT_FORM = {
  states: [...states.map(s => s.value)],
  federalDnc: true,
  tcpa: true,
  dncComplainers: true,
  compareName: false,
  addNumbers: false,
}

export default function MassScrubStep1() {
  const [upload] = useMutation(UPLOAD_FILE);
  const navigate = useNavigate();
  const { hasAdminPermissions } = usePermissions();
  const { setValue, watch, reset } = useForm<ScrubForm>();

  useEffect(() => { reset(DEFAULT_FORM) }, [reset]);
  const l = watch();

  const onUpload = async (files: File[]) => {
    // Checks
    const {
      data: {
        singleUpload: { uuid, preview, ext },
      },
    } = await upload({ variables: { upload: files[0] } });
    navigate(ROUTES.MASS_SCRUB_STEP_2, {
      state: {
        uuid,
        preview,
        ext,
        ...l,
      },
    });
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          <H2 text="Phone " fontSize="32px" fontWeight={400} />
          <div className="pl-2">
            <H1 text="Scrub" fontSize="32px" />
          </div>
        </div>
      </div>
      <div className="pt-2">
        <Paragraph text="On this page you can compare your phone numbers with our database to find any DNC/TCPA numbers" />
      </div>
      <div className="py-2 ml-[-20px]">
        <LeftTextBtn btnText="Back to List" link={ROUTES.PHONE_SCRUB} />
      </div>
      <div className="pb-3">
        <H2 text="Step 1 of 2" fontSize="24px" fontWeight={300} />
      </div>
      <div className="flex flex-row">
        <div className="pr-10" style={{ width: "600px" }}>
          {/* <Group position="apart" spacing="sm" grow>
            <BoxCB
              text="TCPA"
              width="153px"
              checked={l.tcpa}
              onClick={(val) => setValue("tcpa", val)}
              disabled={l.addNumbers}
            />
            <BoxCB
              text="Federal DNC"
              width="177px"
              checked={l.federalDnc}
              onClick={(val) => setValue("federalDnc", val)}
              disabled={l.addNumbers}
            />
            <BoxCB
              text="DNC Complainers"
              width="200px"
              checked={l.dncComplainers}
              onClick={(val) => setValue("dncComplainers", val)}
              disabled={l.addNumbers}
            />
          </Group> */}
          {/* <div className="flex items-center pt-3">
            <div className="mr-3">
              <MultiSelect
                // width={540} 
                searchable
                placeholder="States"
                onChange={(val) => setValue('states', val)}
                value={l.states}
                styles={{ root: { minHeight: '40px !important', minWidth: '300px' }, defaultVariant: { minHeight: '40px !important' } }}
                // size="sm"
                data={states}
                disabled={l.addNumbers}
              />
            </div>
            <InfoTooltip text="If specified, application will also be scrubing out phone numbers of requested states DNC lists." />
          </div> */}
          <div className="flex items-center pt-3">
            <div className="pr-2">
              <GeneralCB
                id={'name-recognition'}
                setChecked={(val) => setValue("compareName", val)}
                disabled={l.addNumbers}
              />
            </div>
            <label htmlFor="name-recognition" className="text-[#626262] pr-3">Name Recognition Algorithm (NRA)</label>
            <InfoTooltip text="If number was not found in our database, we are comparing phone numbers associated names with names in database and respecting state of phone number. We are removing number if provided name is a name of any serial litigator listed in our database." />
          </div>
          {hasAdminPermissions() && <div className="flex items-center pt-8">
            <Button
              className="mb-5 gradient-button"
              onClick={() => setValue("addNumbers", !l.addNumbers)}
              type="submit"
              radius={"lg"}
            >
              <div className="flex items-center gap-3">
                <Checkbox
                  color={"red"}
                  checked={l.addNumbers}
                  onChange={(event) =>
                    setValue("addNumbers", event.currentTarget.checked)
                  }
                />
                Add phone numbers to internal DNC
              </div>
            </Button>
          </div>}
        </div>
        <div className="pr-20" style={{ width: "500px" }}>
          <DropZone onDrop={onUpload} />
        </div>
      </div>
    </>
  );
}
