import { Text } from '@mantine/core';

interface LinkProps {
  text: string
  fontSize: string
}

export default function Link( { text, fontSize }: LinkProps ) {
  return (
    <>
    <Text weight={400} underline style={{ color: '#0F182D', fontFamily: '', fontSize: fontSize }}> 
        {text}
    </Text>
    </>
  );
}