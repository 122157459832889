import { Button, Avatar } from '@mantine/core';
import { Link } from 'react-router-dom';

interface LeftTextBtnProps {
  btnText: string
  link: string;
}

export default function LeftTextBtn({ btnText, link }: LeftTextBtnProps) {

  return (
    <Button leftIcon={<Avatar src={'/svg/text-arrow.svg'} size={20} />} variant="white"
      styles={() => ({
        root: {
          '&:hover': {
            color: '#cdcdcd',
            fill: '#cdcdcd'
          },
          color: '#0f182d',
          fill: '#0f182d'
        },
      })}
      component={Link} to={link}
    >
      {btnText}
    </Button>
  );
}