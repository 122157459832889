import { Text } from '@mantine/core';

interface H1Props {
  text: string
  fontSize: string
  textAlign?: "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent";
}

export default function H1( { text, fontSize, textAlign }: H1Props ) {
  return (
    <>
    <Text weight={700} style={{ color: '#0f182d', fontFamily: '', fontSize: fontSize, textAlign: textAlign ? textAlign : 'left'  }}> 
        {text}
    </Text>
    </>
  );
}