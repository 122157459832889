import { Route, Routes } from "react-router-dom"
import Dashboard from "../components/pages/Dashboard"
import { Logout } from "../components/pages/Logout"
import Logs from "../components/pages/Logs"
import PhoneScrub from "../components/pages/PhoneScrub"
import MassScrubStep1 from "../components/pages/MassScrubStep1"
import MassScrubStep2 from "../components/pages/MassScrubStep2"
import Profile from "../components/pages/Profile"
import SingleScrub from "../components/pages/SingleScrub"
import SingleUser from "../components/pages/SingleUser"
import UIKit from "../components/pages/UIKit"
import Users from "../components/pages/Users"
import { usePermissions } from "../hooks/usePermissions"
import { ROUTES } from "./config"
const RoutesIntranet = () => {
  const { hasAdminPermissions } = usePermissions();
  return (
    <Routes>
      {/* ROUTES FOR ALL USERS */}
      <Route path={ROUTES.LOGOUT} element={<Logout />}></Route>
      {/* <Route path={ROUTES.HOME} element={<Dashboard />}></Route> */}
      {/* <Route path="/ui-kit" element={<UIKit />}></Route> */}
      <Route path={ROUTES.PROFILE} element={<Profile />}></Route>
      <Route path={ROUTES.PHONE_SCRUB} element={<PhoneScrub />}></Route>
      <Route path={ROUTES.MASS_SCRUB_STEP_1} element={<MassScrubStep1 />}></Route>
      <Route path={ROUTES.MASS_SCRUB_STEP_2} element={<MassScrubStep2 />}></Route>
      <Route path={ROUTES.SINGLE_SCRUB} element={<SingleScrub />}></Route>
      {/* ADMIN ROUTES */}
      {hasAdminPermissions() && (
        <>
          <Route path={ROUTES.LOGS} element={<Logs />}></Route>
          <Route path={ROUTES.USERS} element={<Users />}></Route>
          <Route path={ROUTES.SINGLE_USER} element={<SingleUser />}></Route>
        </>
      )}
    </Routes>
  )
}
export default RoutesIntranet;