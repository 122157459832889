import { SimpleGrid, Box, Button } from '@mantine/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface ButtonsProps{
    btnText: string
    img: string
    link: string
}

interface HNavCardProps {
    buttons: ButtonsProps[]
    numCols: number
    width: string
}
const ButtonHover = ({ text, img }: { text: string, img: string }) => {
    const [hover, setHover] = useState(false);
    const onMouseOver = () => setHover(true);
    const onMouseLeave = () => setHover(false);
    return (
    <Button 
        onMouseOver={onMouseOver} 
        onMouseLeave={onMouseLeave} 
        variant="white" 
        styles={() => ({ root: {color:'#0f182d', "box-shadow": "1px 1px 5px #0f182d3d", '&:hover': {color: 'white', backgroundColor: '#0f182d'}} })}
    >
        <div className='flex items-center gap-2'>
            <img className={hover ? 'image-hover' : ''} src={img} alt="" />
            {text}
        </div>
    </Button>)
}
export default function HNavCard( { buttons, numCols, width }:HNavCardProps ) {

    return (
        <div>
            <Box
            sx={(theme) => ({
                display: 'block',
                width: 'fit-content',
                height:'56px',
            })}
            >
                <SimpleGrid cols={numCols} spacing="xs" style={{ height: '56px' }}>
                    {buttons.map((b, i) => {
                    return (
                        <div className='flex justify-center items-center' key={i}>
                            <Link to={b.link}>
                                <ButtonHover text={b.btnText} img={b.img} />
                            </Link>
                        </div>
                    )})}
                </SimpleGrid>
            </Box>
        </div>
    );
}