import './App.css';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import ApolloErrorModal from './components/Modals/ApolloErrorModal';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ROLES, GET_USER_ROLE } from './graphql/queries';
import { useSetRecoilState } from 'recoil';
import { roles, userRole } from './components/state/atoms';
import NavBar from './components/Layout/NavBar';
import SideBar from './components/Layout/SideBar';
import RoutesIntranet from './routes';
import { ROUTES } from './routes/config';
import { removeLogs } from './hooks/removeLogs';
import { useSendVerification } from './hooks/useSendVerification';


function App() {
  const { loginWithRedirect, isAuthenticated, isLoading, error, logout, user, } = useAuth0();
  const [getRole] = useLazyQuery(GET_USER_ROLE);
  const [getAllRoles] = useLazyQuery(GET_ALL_ROLES);
  const setUserRole = useSetRecoilState(userRole);
  const setRoles = useSetRecoilState(roles);
  const [emailSent, setEmailSent] = useState(false);
  const [triggerEmail, setId, isLoadingEmail] = useSendVerification(() => setEmailSent(true))
  // const navigate = useNavigate();
  const location = useLocation();
  // Get user info and role from API
  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) loginWithRedirect();
    else if (user) {
      setId(user.sub || "")
      getRole({ variables: { id: user?.sub }, onCompleted: data => setUserRole(data.userRole) })
      getAllRoles({ onCompleted: data => setRoles(data.getAllRoles) })
      // navigate(ROUTES.PHONE_SCRUB)
      location.pathname = ROUTES.PHONE_SCRUB
    }
  }, [loginWithRedirect, isAuthenticated, isLoading, error, logout, getRole, user, setUserRole]);

  // Disable console.log on production
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      removeLogs(false, true)
    }
  }, []);
  const links = [
    // {
    //   link: ROUTES.HOME,
    //   color: "#809FB8",
    //   text: "Dashboard",
    // },
    {
      link: ROUTES.PHONE_SCRUB,
      color: "#0f182d",
      text: "Phone Scrub",
    },
    {
      link: ROUTES.USERS,
      color: "#0f182d",
      text: "Users",
    },
    {
      link: ROUTES.LOGS,
      color: "#0f182d",
      text: "Logs",
    }
  ]

  return (
    <>
      {/* <Head>
          <title>WCL Intranet</title>
          <meta name="robots" content="noindex,nofollow" />
      </Head> */}
      <div>
        {/* <BrowserRouter> */}
        <ApolloErrorModal />
        {isLoading || error ? (
          error ? (
            <div className='absolute w-screen h-screen'>
              <div className='w-full h-full flex items-center justify-center flex-col gap-5'>
                Only West Capital Lending emails are allowed
                <Button className='bg-[#0f182d]' onClick={() => logout({ returnTo: process.env.REACT_APP_PUBLIC_URL })}>Logout</Button>
              </div>
            </div>
          ) : (
            <></>
          )
        ) : (
          user?.email_verified ? (
            <section className='w-full'>
              <div className='flex flex-row'>
                <SideBar
                  links={links}
                />
                <div className='flex w-full md:pl-72 md:pr-10'>
                  <div className='flex flex-col w-full'>
                    <NavBar
                      links={links}
                    />
                    <div className='pt-5 md:pt-0 px-10 md:px-0'>
                      <RoutesIntranet />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div className='absolute w-screen h-screen text-center'>
              {!emailSent ? (
                <div className='w-full h-full flex items-center justify-center flex-col gap-5 relative'>
                  <div className='m-auto absolute top-0 py-5  shadow-md w-full flex items-center justify-center'>
                    <img src="/Images/west-capital-lending-logo.png" width={200} alt="" />
                  </div>
                  <div>
                    <img src="/svg/email.svg" alt="" />
                  </div>
                  Please click on the link that has been sent to your email account to verify your email and refresh this page.
                  <br></br>
                  If no email has been received, please click the button below to resend the verification email.
                  <Button loading={isLoadingEmail} className='bg-[#0f182d]' onClick={() => triggerEmail()}>Send Verification Email</Button>
                </div>
              ) : (
                <div className='w-full h-full flex items-center justify-center flex-col gap-5 '>
                  <div className='m-auto absolute top-0 py-5  shadow-md w-full flex items-center justify-center'>
                    <img src="/Images/west-capital-lending-logo.png" width={200} alt="" />
                  </div>
                  <div>
                    <img src="/svg/send.svg" alt="" />
                  </div>
                  A new email has just been sent, please check your inbox.
                </div>
              )}
            </div>
          ))}
        {/* </BrowserRouter> */}
      </div>
    </>
  );
}

export default App;
