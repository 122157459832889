import { gql } from "@apollo/client";

export const EDIT_PROFILE = gql`
  mutation editProfile(
    $id: ID!
    $phone: String
    $address: String
    $state: String
    $city: String
    $zipcode: String
    $first_name: String
    $last_name: String
  ) {
    editProfile(
      id: $id
      phone: $phone
      address: $address
      state: $state
      city: $city
      zipcode: $zipcode
      last_name: $last_name
      first_name: $first_name
    ) {
      status
      error
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUser(
    $id: ID!
    $role: String!
  ) {
    editUser(
      id: $id
      role: $role
    ) {
      status
      error
    }
  }
`;

export const COMPARE_SINGLE = gql`
  mutation singleScrub($input: String!, $type: String!) {
    compareSingle(input: $input, type: $type) {
      clean
      status
      phone
      name
    }
  }
`;

export const ADD_CONTACT = gql`
  mutation addContact($name: String!, $email: String!, $phone: String!) {
    addContact(name: $name, email: $email, phone: $phone) {
      id
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($upload: Upload!) {
    singleUpload(file: $upload) {
      uuid
      preview
      ext
    }
  }
`;

export const COMPARE_FILE = gql`
  mutation compareFile(
    $id: ID!
    $phone: Int!
    $phone2: Int
    $phone3: Int
    $phone4: Int
    $first_name: Int
    $last_name: Int
    $email: Int
    $compareName: Boolean
    $states: [String],
    $federalDnc: Boolean,
    $tcpa: Boolean,
    $dncComplainers: Boolean,
    $exportedColumns: [Int],
    $keepHeaderColumns: [Int],
    $outputFileFormat: String,
    $groupByPerson: Boolean
  ) {
    compareFile(
      id: $id
      phone: $phone
      phone2: $phone2
      phone3: $phone3
      phone4: $phone4
      first_name: $first_name
      last_name: $last_name
      email: $email
      compareName: $compareName
      states: $states,
      federalDnc: $federalDnc,
      tcpa: $tcpa,
      dncComplainers: $dncComplainers
      exportedColumns: $exportedColumns
      keepHeaderColumns: $keepHeaderColumns
      outputFileFormat: $outputFileFormat
      groupByPerson: $groupByPerson
    ) {
      ok
    }
  }
`;

export const ADD_CONTACTS_FILE = gql`
  mutation AddContacts($id: ID!, $phone: Int!, $name: Int, $email: Int) {
    addContacts(id: $id, phone: $phone, name: $name, email: $email) {
      addedCount
      duplicateCount
      invalidCount
      invalidNumbers {
        phone
        email
        name
      }
    }
  }
`;
