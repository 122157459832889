import MobileMenu from "../Navigation/MobileMenu";
import ProfileDD from "../Dropdowns/ProfileDD";
import { menuSidebarOpenState} from '../state/atoms';
import { useSetRecoilState } from 'recoil';

interface MobileMenuProps{
    links: {
        link: string,
        color: string,
        text: string,
    }[]
}

export default function NavBar( { links }:MobileMenuProps ) {
    const setMenuSidebarOpen = useSetRecoilState(menuSidebarOpenState);

    return (
        <div className='flex justify-between md:justify-end items-center full' style={{ height: '80px' }}>
            <div className="md:hidden cursor-pointer pl-5 bg-white" onClick={() => setMenuSidebarOpen(true)}>
                <img src="/Images/logo.png" alt="" />
            </div>
            <MobileMenu
                links={links}
            />
            <div className='pr-5 md:pr-0'>
                <ProfileDD/>
            </div>
        </div>
    );
}